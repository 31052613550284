<template>
    <div class="privacy-policy">
        <div class="langugage">
            <span class="lang" :class="$i18n.locale == 'zh_CN' ? 'active' : ''" @click="change('zh_CN')">简</span>
            <span>/</span>
            <span class="lang" :class="$i18n.locale == 'zh_HK' ? 'active' : ''" @click="change('zh_HK')">繁</span>
            <span>/</span>
            <span class="lang" :class="$i18n.locale == 'en_US' ? 'active' : ''" @click="change('en_US')">EN</span>
        </div>
        <div>
            <h3 class="title" v-text="info.title"></h3>
            <div class="main" v-html="info.content"></div>
        </div>
    </div>
</template>

//
<script>
export default {
    data() {
        return {
            info: {},
        };
    },
    created() {
        let lang = this.$route.query.type;
        if (['en_US', 'zh_HK', 'zh_CN'].includes(lang)) {
            this.$setItem('lang', lang);
            this.$i18n.locale = lang;
        }
        this.getPageDetail();
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        change(lang) {
            this.$setItem('lang', lang);
            window.location.href = window.location.href.split('?').shift();
        },
        getPageDetail() {
            let name = this.$route.query.name || this.$route.meta.name;
            this.$api.getPageDetail({ name,source:2 }).then((res) => {
                if (res.code == 200) {
                    this.info = res.data;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.privacy-policy {
    padding: 30px;
    line-height: 2;
    .langugage {
        padding-right: 100px;
        text-align: right;
        span {
            padding: 0 5px;
            &.lang {
                cursor: pointer;
            }
            &.active {
                color: red;
            }
        }
    }
    h3.title {
        text-align: center;
        padding: 10px 0;
    }
    .main {
        h4 {
            padding: 5px 0;
        }
    }
}
</style>
